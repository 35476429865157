const state = () => ({
	loaded: false,
	data: null,
	defaultData: [
		{
			id: 1,
			label: 'Sign Off Level 1',
		},
		{
			id: 2,
			label: 'Sign Off Level 2',
		},
		{
			id: 3,
			label: 'Sign Off Level 3',
		},
	],
});

const mutations = {
	getData(state) {
		state.data = state.defaultData;

		state.loaded = true;
	},
};

// actions
const actions = {
	getData(context) {
		context.commit('getData');
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
