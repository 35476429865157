import store from '@/_store';
const state = () => ({
	currentAlert: {
		type: '',
		message: '',
	},
	alerts: [],
});

const mutations = {
	checkAlert(state) {
		if (state.alerts.length > 0) {
			if (state.currentAlert.message !== '') {
				store.commit('alert/checkAgain');
			} else {
				store.commit('alert/updateCurrent');
			}
		}
	},
	checkAlertNow() {
		setTimeout(function () {
			store.commit('alert/checkAlert');
		}, 100);
	},
	checkAgain() {
		setTimeout(function () {
			store.commit('alert/checkAlert');
		}, 3500);
	},
	updateCurrent(state) {
		state.currentAlert = state.alerts[0];
		store.commit('alert/removeAlert');

		// console.log("state.currentAlert", state.currentAlert);

		setTimeout(function () {
			store.commit('alert/removeCurrent');
		}, 3000);
	},
	addAlert(state, data) {
		state.alerts.push(data);

		store.commit('alert/checkAlertNow');
	},
	removeAlert(state) {
		state.alerts.shift();

		store.commit('alert/checkAlertNow');
	},
	removeCurrent(state) {
		state.currentAlert = { type: '', message: '' };
	},
};

const actions = {
	checkAlert(context) {
		context.commit('checkAlert');
	},
	checkAlertNow(context) {
		context.commit('checkAlertNow');
	},
	checkAgain(context) {
		context.commit('checkAgain');
	},
	updateCurrent(context) {
		context.commit('updateCurrent');
	},
	addAlert(context, data) {
		context.commit('addAlert', data);
	},
	removeAlert(context, data) {
		context.commit('removeAlert', data);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
