import { createToast } from 'mosha-vue-toastify';

const formValidationCheck = (passTitle, passDescription, passType, value, check, valueTwo) => {
	if (check === 'password') {
		if (value !== valueTwo) {
			const title = passTitle || 'Validation Error';
			const description = passDescription || 'Please enter data into the fields marks required.';
			const type = passType || 'warning';
			createToast({ title: title, description: description }, { type: type, showIcon: true, position: 'bottom-right' });
			return false;
		} else {
			return true;
		}
	} else if (check === 'noDefaultID') {
		if (value === '00000000-0000-0000-0000-000000000000') {
			const title = passTitle || 'Validation Error';
			const description = passDescription || 'Please enter data into the fields marks required.';
			const type = passType || 'warning';
			createToast({ title: title, description: description }, { type: type, showIcon: true, position: 'bottom-right' });
		} else {
			return true;
		}
	} else {
		if (value === '' || value === null || value === undefined || value.length < 1) {
			const title = passTitle || 'Validation Error';
			const description = passDescription || 'Please enter data into the fields marks required.';
			const type = passType || 'warning';
			createToast({ title: title, description: description }, { type: type, showIcon: true, position: 'bottom-right' });
		} else {
			return true;
		}
	}
};

export { formValidationCheck };
