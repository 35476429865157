const state = () => ({
	loaded: false,
	data: null,
	defaultData: [
		{
			id: 1,
			firstName: 'Joe',
			lastName: 'Blogs',
			emailAddress: 'joe.blogs@map-cms.co.uk',
			phoneNumber: '01234567890',
			inviteCode: '',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
		},
		{
			id: 2,
			firstName: 'Jane',
			lastName: 'Smith',
			emailAddress: '',
			phoneNumber: '01234567890',
			inviteCode: 'A7JW#5R',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
		},
		{
			id: 3,
			firstName: 'Tom',
			lastName: 'Cobley',
			emailAddress: 'tom.cobley@map-cms.co.uk',
			phoneNumber: '',
			inviteCode: '',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
		},
		{
			id: 4,
			firstName: 'Fred',
			lastName: 'Nerk',
			emailAddress: 'fred.nerk@map-cms.co.uk',
			phoneNumber: '01234567890',
			inviteCode: '',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
		},
		{
			id: 5,
			firstName: 'Juan',
			lastName: 'Perez',
			emailAddress: '',
			phoneNumber: '01234567890',
			inviteCode: 'K3JT!6H',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
		},
	],
});

const mutations = {
	getData(state) {
		state.data = state.defaultData;

		state.loaded = true;
	},
};

// actions
const actions = {
	getData(context) {
		context.commit('getData');
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
