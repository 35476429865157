const state = () => ({
	loaded: false,
	data: null,
	defaultData: [
		{
			id: 1,
			label: 'Asset 1',
		},
		{
			id: 2,
			label: 'Asset 2',
			children: [
				{
					id: 3,
					label: 'Asset 3',
				},
				{
					id: 4,
					label: 'Asset 4',
					children: [
						{
							id: 5,
							label: 'Asset 5',
						},
					],
				},
			],
		},
	],
});

const mutations = {
	getData(state) {
		state.data = state.defaultData;

		state.loaded = true;
	},
};

// actions
const actions = {
	getData(context) {
		context.commit('getData');
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
