const state = () => ({
	loaded: false,
	data: null,
	defaultData: [
		{
			id: 1,
			title: 'Active Jobs',
			jobCount: 0,
			jobs: [
				{
					id: 1,
					description: 'Job Template B - 02-08-2021 - Daily',
					status: 'In Progress',
					assigness: '2 Hidden Admin',
					location: 'Fantasy Island Holdings Limited',
					asset: 'Millenium',
					task: {
						green: 0,
						amber: 0,
						red: 0,
						grey: 0,
						black: 0,
					},
				},
			],
		},
		{
			id: 2,
			title: 'Overdue Jobs',
			jobCount: 0,
			jobs: [
				{
					id: 1,
					description: 'Job Template B - 02-08-2021 - Daily',
					status: 'In Progress',
					assigness: '2 Hidden Admin',
					location: 'Fantasy Island Holdings Limited',
					asset: 'Millenium',
					task: {
						green: 0,
						amber: 0,
						red: 0,
						grey: 0,
						black: 0,
					},
				},
				{
					id: 2,
					description: 'Job Template B - 02-08-2021 - Daily',
					status: 'In Progress',
					assigness: '2 Hidden Admin',
					location: 'Fantasy Island Holdings Limited',
					asset: 'Millenium',
					task: {
						green: 0,
						amber: 0,
						red: 0,
						grey: 0,
						black: 0,
					},
				},
				{
					id: 3,
					description: 'Job Template B - 02-08-2021 - Daily',
					status: 'In Progress',
					assigness: '2 Hidden Admin',
					location: 'Fantasy Island Holdings Limited',
					asset: 'Millenium',
					task: {
						green: 0,
						amber: 0,
						red: 0,
						grey: 6,
						black: 0,
					},
				},
				{
					id: 4,
					description: 'Job Template B - 02-08-2021 - Daily',
					status: 'In Progress',
					assigness: '2 Hidden Admin',
					location: 'Fantasy Island Holdings Limited',
					asset: 'Millenium',
					task: {
						green: 1,
						amber: 5,
						red: 1,
						grey: 0,
						black: 0,
					},
				},
			],
		},
		{
			id: 3,
			title: 'Ad-Hox Jobs',
			jobCount: 0,
			jobs: [
				{
					id: 1,
					description: 'Job Template B - 02-08-2021 - Daily',
					status: 'In Progress',
					assigness: '2 Hidden Admin',
					location: 'Fantasy Island Holdings Limited',
					asset: 'Millenium',
					task: {
						green: 0,
						amber: 0,
						red: 0,
						grey: 0,
						black: 0,
					},
				},
			],
		},
	],
});

const mutations = {
	getData(state) {
		state.data = state.defaultData;

		if (state.data) {
			for (let i = 0; i < state.data.length; i++) {
				state.data[i].open = false;

				for (let j = 0; j < state.data[i].jobs.length; j++) {
					state.data[i].jobCount++;
				}
			}
		}

		state.loaded = true;
	},
};

// actions
const actions = {
	getData(context) {
		context.commit('getData');
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
