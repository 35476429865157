<template>
	<div class="Comp-Sidebar" :class="{ Expanded: expandable }">
		<div class="compLogo">
			<img src="/images/MAP.png" alt="Map Logo" />
		</div>
		<div class="menu">
			<div class="singleMenuItem" v-for="item in menuAccess" :key="item.name">
				<router-link :to="item.accessPath || '/'">
					<div class="icons">
						<i :class="item.iconName"></i>
						<p>{{ item.name }}</p>
					</div>
				</router-link>
			</div>

			<a @click="sidebarToggle()">
				<div class="icons expandable">
					<i class="fas fa-ellipsis-v"></i>
					<p>Expand</p>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
	name: 'Comp-Sidebar',

	setup() {
		const store = useStore();

		const currentPath = location.protocol + '//' + location.host;
		const menuItems = ref(computed(() => store.state.currentUser.user.menuPermissions));
		const currentPage = ref(computed(() => store.state.sidebar.currentPage));
		const expandable = ref(computed(() => store.state.sidebar.expanded));

		const menuAccess = computed(() => {
			const availableMenuList = menuItems.value
				? menuItems.value.filter((item) => item?.permissions.find((permission) => permission.name === 'VIEW').access)
				: [];
			return availableMenuList.sort((a, b) => parseFloat(a.orderId) - parseFloat(b.orderId));
		});

		const sidebarToggle = () => {
			store.commit('sidebar/sidebarToggle');
		};

		return {
			currentPath,
			menuItems,
			currentPage,
			expandable,
			sidebarToggle,
			menuAccess,
		};
	},
};
</script>

<style lang="scss" scoped>
.compLogo {
	position: fixed;
	top: 15px;
	left: 20px;
	height: 50px;
	width: 50px;
	border-radius: 10px 10px 10px 10px;
	overflow: hidden;

	img {
		height: 50px;
		width: 50px;
	}
}
.Comp-Sidebar {
	position: fixed;
	background-color: var(--cta);
	top: 80px;
	left: 20px;
	height: calc(100% - 100px);
	width: 50px;
	border-radius: 10px;
	box-shadow: 0 0 25px 0 rgba(150, 150, 150, 0.5);
	display: flex;
	z-index: 9;
	transition: 0.3s ease;

	.menu {
		position: relative;

		.icons {
			font-size: 40px;
			position: relative;
			height: 55px;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--cta);
			border-radius: 10px;
			transition: 0.6s ease;

			i {
				margin: 0;
				font-size: 19px;
				color: var(--text-dark);
				transition: 0.6s ease;
			}

			p {
				position: absolute;
				top: 0px;
				bottom: 0px;
				left: 0px;
				height: 29px;
				width: auto;
				max-width: 0;
				margin: auto;
				display: inline-block;
				padding: 6px 0;
				font-size: 16px;
				font-weight: 400;
				color: var(--text-alt);
				line-height: 100%;
				white-space: nowrap;
				overflow: hidden;
				transition: 0s ease;
				z-index: -1;
			}
			p:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				padding: 6px 10px;
				height: 100%;
				background-color: var(--cta);
				transition: 0.6s ease;
				z-index: -1;
			}
		}
		.icons.expandable {
			position: absolute;
			left: 0;
			bottom: 0;
		}

		.router-link-active {
			.icons {
				p,
				.far,
				.fas {
					color: var(--bs-gray-400);
				}
			}
		}
		.icons.hover {
			color: black;
		}
		a:hover {
			.icons {
				i {
					color: var(--text-dark);
				}
				p {
					left: 50px;
					width: auto;
					max-width: 300px;
					padding: 6px 10px;
					transition: 0.6s ease;
				}
				p:before {
					width: 100%;
				}
			}
		}
		a {
			text-decoration: none;
			cursor: pointer;
		}
	}
}
.Comp-Sidebar.Expanded {
	width: 180px;

	.menu {
		.icons {
			p {
				left: 50px;
				width: auto;
				padding: 6px 0;
				background-color: transparent;
				color: var(--text-alt);
				transition: 0.6s ease;
				max-width: 180px !important;
			}
		}
		.router-link-active {
			.icons {
				p,
				.far,
				.fas {
					color: var(--bs-gray-400);
				}
			}
		}
	}
}
</style>
