import { ref } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { authprotected, unauthprotected } from './authentication';

const routes = [
	/* ----- AUTH ------------------------- */
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/_auth/login'),
		beforeEnter: unauthprotected,
	},
	{
		path: '/forgotten-password',
		name: 'forgottenPassword',
		component: () => import('@/views/_auth/forgottenPassword'),
		beforeEnter: unauthprotected,
	},
	{
		path: '/password-reset',
		name: 'passwordReset',
		component: () => import('@/views/_auth/passwordReset'),
		beforeEnter: unauthprotected,
	},

	/* ----- DASHBOARD ------------------------- */
	{
		path: '/',
		name: 'Dashboard',
		component: () => import('@/views/dashboard/index'),
		beforeEnter: authprotected,
	},

	/* ----- SCHEDULE CENTER ------------------------- */
	{
		path: '/schedule-centre',
		name: 'scheduleCenter',
		component: () => import('@/views/scheduleCentre/index'),
		beforeEnter: authprotected,
	},
	{
		path: '/schedule-centre/schedule-job',
		name: 'scheduleAJob',
		component: () => import('@/views/scheduleCentre/scheduleAJob'),
		beforeEnter: authprotected,
	},
	{
		path: '/schedule-centre/view-job',
		name: 'viewAJob',
		component: () => import('@/views/scheduleCentre/view'),
		beforeEnter: authprotected,
	},

	{
		path: '/schedule-centre/schedule-job-template-checklist',
		name: 'scheduleAJobTemplateChecklist',
		component: () => import('@/views/scheduleCentre/scheduleAJobTemplateChecklist'),
		beforeEnter: authprotected,
	},

	/* ----- ASSET MANAGER ------------------------- */
	{
		path: '/asset-manager',
		name: 'assetManager',
		component: () => import('@/views/assetManager/index'),
		beforeEnter: authprotected,
	},
	{
		path: '/asset-manager/child',
		name: 'assetChild',
		component: () => import('@/views/assetManager/levelDown'),
		beforeEnter: authprotected,
	},
	{
		path: '/asset-manager/create-asset',
		name: 'createNewAsset',
		component: () => import('@/views/assetManager/create'),
		beforeEnter: authprotected,
	},
	{
		path: '/asset-manager/view-asset',
		name: 'viewNewAsset',
		component: () => import('@/views/assetManager/view'),
		beforeEnter: authprotected,
	},

	/* ----- USER MANAGER ------------------------- */
	{
		path: '/user-manager',
		name: 'userManager',
		component: () => import('@/views/userManager/index'),
		beforeEnter: authprotected,
	},
	{
		path: '/user-manager/create-new-user',
		name: 'createNewUser',
		component: () => import('@/views/userManager/create'),
		beforeEnter: authprotected,
	},
	{
		path: '/user-manager/view-user',
		name: 'viewUser',
		component: () => import('@/views/userManager/view'),
		beforeEnter: authprotected,
	},
	{
		path: '/user-manager/gps-user',
		name: 'userGPSmap',
		component: () => import('@/views/userManager/userGPSMap'),
		beforeEnter: authprotected,
	},

	/* ----- AUDIT LOG ------------------------- */
	{
		path: '/audit-log',
		name: 'auditLog',
		component: () => import('@/views/audit/index'),
		beforeEnter: authprotected,
	},

	/* ----- SETTINGS ------------------------- */
	{
		path: '/settings',
		name: 'settings',
		component: () => import('@/views/settings/index'),
		beforeEnter: authprotected,
	},
	/* ----- TEMPLATES ------------------------- */

	/* ----- COMPANY ------------------------- */
	{
		path: '/templates/companies/view-company',
		name: 'viewCompany',
		component: () => import('@/views/templates/companies/viewCompany'),
		beforeEnter: authprotected,
	},
	/* ----- DASHBOARD TEMPLATES ------------------------- */
	{
		path: '/companies/create-company',
		name: 'createCompany',
		component: () => import('@/views/templates/companies/create'),
		beforeEnter: authprotected,
	},

	/* ----- JOB TEMPLATE ------------------------- */
	{
		path: '/templates/create-checklist-job-template',
		name: 'createChecklistJobTemplate',
		component: () => import('@/views/templates/jobs/createChecklist'),
		beforeEnter: authprotected,
	},
	{
		path: '/templates/create-hotspot-job-template',
		name: 'createHotspotJobTemplate',
		component: () => import('@/views/templates/jobs/createHotspot'),
		beforeEnter: authprotected,
	},
	{
		path: '/templates/jobs/view-hot-spot-template',
		name: 'viewHotSpotTemplate',
		component: () => import('@/views/templates/jobs/viewHotSpotTemplate'),
		beforeEnter: authprotected,
	},
	{
		path: '/templates/jobs/view-checklist-template',
		name: 'viewChecklistTemplate',
		component: () => import('@/views/templates/jobs/viewChecklistTemplate'),
		beforeEnter: authprotected,
	},
	{
		path: '/templates/template-category/view',
		name: 'viewTemplateCategory',
		component: () => import('@/views/templates/category/view'),
		beforeEnter: authprotected,
	},

	/* ----- ASSET TEMPLATE ------------------------- */
	{
		path: '/templates/create-asset-template',
		name: 'createAssetTemplate',
		component: () => import('@/views/templates/asset/create'),
		beforeEnter: authprotected,
	},
	{
		path: '/templates/view-asset-template',
		name: 'viewAssetTemplate',
		component: () => import('@/views/templates/asset/viewAssetTemplate'),
		beforeEnter: authprotected,
	},

	/* ----- USER TEMPLATE ------------------------- */
	{
		path: '/templates/create-user-template',
		name: 'createUserTemplate',
		component: () => import('@/views/templates/userTemplates/create'),
		beforeEnter: authprotected,
	},
	{
		path: '/templates/view-user-template',
		name: 'viewUserTemplate',
		component: () => import('@/views/templates/userTemplates/view'),
		beforeEnter: authprotected,
	},

	/* ----- CATEGORIES ------------------------- */
	{
		path: '/templates/create-category',
		name: 'createTemplateCategory',
		component: () => import('@/views/templates/category/create'),
		beforeEnter: authprotected,
	},

	/* ----- MESSAGE CENTER ------------------------- */
	{
		path: '/messages',
		name: 'messageCenter',
		component: () => import('@/views/messageCenter/index'),
		beforeEnter: authprotected,
	},
	{
		path: '/message-groups',
		name: 'newMessageGroups',
		component: () => import('@/views/messageCenter/newMessageGroup'),
		beforeEnter: authprotected,
	},
	{
		path: '/message-group-details',
		name: 'messageGroupDetails',
		component: () => import('@/views/messageCenter/messageGroupDetails'),
		beforeEnter: authprotected,
	},
	/* ----- NOTIFICATION CENTER ------------------------- */
	{
		path: '/notifications',
		name: 'notificationCenter',
		component: () => import('@/views/notificationCenter/notificationCenter'),
		beforeEnter: authprotected,
	},

	/* ----- REPORT VIEWER ------------------------- */
	{
		path: '/viewer',
		name: 'viewerHolder',
		component: () => import('@/views/reportViewer/viewerHolder'),
		beforeEnter: authprotected,
	},

	/* ----- NOT FOUND ---------------- */
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('@/views/notFound/index.vue'),
		beforeEnter: authprotected,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;

router.onError((error) => {
	console.warn('Check error: step 1 => ', error); //TODO clean after checking

	if (/loading chunk \d* failed./i.test(error?.message)) {
		const reloadAttempts = Number(localStorage.getItem('reloadAttempts') || 0);
		console.warn('Check error: step 2 => ', error.message); //TODO clean after checking
		if (reloadAttempts < 3) {
			console.warn('Check error: step 3 => ', error.message); //TODO clean after checking
			localStorage.setItem('reloadAttempts', (reloadAttempts + 1).toString());
			window.location.reload();
		} else {
			localStorage.removeItem('reloadAttempts');
		}
	}
});

const previousRoute = ref(null);
router.beforeEach((to, from, next) => {
	previousRoute.value = from;
	next();
});
export { previousRoute };
