const state = () => ({
	success: false,
	successMessage: '',
});

const mutations = {
	post(state, data) {
		const date = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString().slice(0, 5);

		console.log('data', data);
		state.success = true;
		state.successMessage = 'Your job has been scheduled successfully for ' + date;
	},
};

// actions
const actions = {
	post(context, data) {
		context.commit('post', data);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
