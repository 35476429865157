import { createStore } from 'vuex';

/* ----- MODULES ------------------------- */
import currentUser from './Modules/currentUser';
import sidebar from './Modules/sidebar';
import data from './Modules/data';
import messages from './Modules/messages';
import alert from './Modules/alert';
import authPopup from './Modules/loginPopup';
import report from './Modules/report';

/* ----- DATA ------------------------- */
import assetsList from './DataFunctions/assetsList';
import scheduleCenterList from './DataFunctions/scheduleCenterList';
import userManagerData from './DataFunctions/userManagerData';

/* ----- DROPDOWNS ------------------------- */
import dropdownAssets from './DataFunctions/dropdownAssets';
import dropdownCompletionDate from './DataFunctions/dropdownCompletionDate';
import dropdownGroups from './DataFunctions/dropdownGroups';
import dropdownJobsTemplates from './DataFunctions/dropdownJobsTemplates';
import dropdownPeriodicity from './DataFunctions/dropdownPeriodicity';
import dropdownPriority from './DataFunctions/dropdownPriority';
import dropdownSignOffLevel from './DataFunctions/dropdownSignOffLevel';
import dropdownUsers from './DataFunctions/dropdownUsers';

/* ----- POST FUNCTIONS ------------------------- */
import scheduleAJob from './PostFunctions/scheduleAJob';

import { API_BASE_URL } from '@/utils/constants';

export default createStore({
	state: {
		api: API_BASE_URL,
	},
	mutations: {},
	actions: {},
	modules: {
		/* ----- MODULES ------------------------- */
		currentUser,
		sidebar,
		data,
		messages,
		report,
		alert,
		authPopup,

		/* ----- DATA ------------------------- */
		assetsList,
		scheduleCenterList,
		userManagerData,

		/* ----- DROPDOWNS ------------------------- */
		dropdownAssets,
		dropdownCompletionDate,
		dropdownGroups,
		dropdownJobsTemplates,
		dropdownPeriodicity,
		dropdownPriority,
		dropdownSignOffLevel,
		dropdownUsers,

		/* ----- POST FUNCTIONS ------------------------- */
		scheduleAJob,
	},
});
