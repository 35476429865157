const state = () => ({
	create: 1,
});

const mutations = {
	createMessageGroup(state, data) {
		state.create = data;
	},
};

// actions
const actions = {
	createMessageGroup(context, data) {
		context.commit('createMessageGroup', data);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
