import { createToast, ToastType } from 'mosha-vue-toastify';

export type ResponseType = {
	data?: {
		result?: string;
		message?: string;
		type?: ToastType;
	};
};

export default function showResponseMessage(response: ResponseType | null, isSuccessMessage?: boolean) {
	if (isSuccessMessage) {
		const title = response && response.data && response.data.result ? response.data.result : 'Success ';
		const description =
			response && response.data && response.data.message ? response.data.message : 'The request has been completed.';
		const type = response && response.data && response.data.type ? response.data.type : 'success';
		createToast({ title: title, description: description }, { type: type, showIcon: true, position: 'bottom-right' });
	} else {
		const title = response && response.data && response.data.result ? response.data.result : 'An error has occurred ';
		const description =
			response && response.data && response.data.message
				? response.data.message
				: 'Unfortunately an error has occurred during this process. Please refresh and try again.';
		const type = response && response.data && response.data.type ? response.data.type : 'danger';
		createToast({ title: title, description: description }, { type: type, showIcon: true, position: 'bottom-right' });
	}
}
