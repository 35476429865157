import axios, { AxiosError } from 'axios';
import router from '@/_router';
import { API_BASE_URL } from '@/utils/constants';

const createAxiosInstance = (url: string) => {
	const api = axios.create({
		baseURL: url,
	});

	api.interceptors.response.use(
		(response) => response,
		async (error: AxiosError) => {
			const { response } = error;

			if (response && response.status === 401) {
				try {
					router.push('/login');
				} catch (e) {
					console.log(e);
				}
			}
			if (response && response.status === 403 && !response.data) {
				response.data = "You don't have permission to access.";
			}
			return Promise.reject(error);
		},
	);

	api.interceptors.request.use(async (config) => {
		try {
			const token = localStorage.c5ad83ab73e67307a1af0d28ae97fdc4;
			if (!config.headers) config.headers = {};
			if (token) config.headers.Authorization = `Bearer ${token}`;
		} catch (e) {
			console.log(e);
		}
		return config;
	});

	return api;
};

export const api = createAxiosInstance(API_BASE_URL);
