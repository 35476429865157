const state = () => ({
	loaded: false,
	data: null,
	defaultData: [
		{
			id: 1,
			name: 'Fantasy Island Holdings Limited',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
			type: 'Company',
			icon: 'fas fa-building',
			children: true,
		},
		{
			id: 2,
			name: 'Mellors Group Events',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
			type: 'Company',
			icon: 'fas fa-building',
			children: true,
		},
		{
			id: 3,
			name: 'F.E.C',
			type: 'Company',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
			icon: 'fas fa-building',
			children: false,
		},
		{
			id: 4,
			name: 'J.M',
			type: 'Company',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
			icon: 'fas fa-building',
			children: true,
		},
		{
			id: 5,
			name: 'J.E.M.',
			type: 'Company',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
			icon: 'fas fa-building',
			children: true,
		},
		{
			id: 6,
			name: 'Mellors Group',
			image: 'https://thelincolnite.co.uk/wp-content/uploads/2021/04/Fantasy-Island-Reopens-12-04-2021-SS-2126.jpg',
			type: 'Company',
			icon: 'fas fa-building',
			children: false,
		},
	],
});

const mutations = {
	getData(state) {
		state.data = state.defaultData;

		state.loaded = true;
	},
};

// actions
const actions = {
	getData(context) {
		context.commit('getData');
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
