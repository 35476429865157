export type SelectOption = {
	label: string;
	value: string;
};

export enum ExtendedPermissionNamesCraft {
	MSGOBSERVER = 'MSGOBSERVER',
	CREATEMSGGROUP = 'CREATEMSGGROUP',
	EDITMSGGROUP = 'EDITMSGGROUP',
	DISABLEMSGGROUP = 'DISABLEMSGGROUP',
	SCHEDULESTATUS = 'SCHEDULESTATUS',
}

export enum JobStatusesCraft {
	WaitingToStart = 'Pending',
	HasBeenSeen = 'Job Read',
	InProgress = 'In progress',
	Pass = 'Passed',
	Other = 'Issue reported',
	Failed = 'Failed',
	Cancelled = 'Cancelled Job',
	Advisory = 'Passed / Advisory',
}

export type SystemIconDTO = {
	id: string;
	name: string | null;
	code: string | null;
};

export type StoreImageDTO = {
	id: string;
	name: string | null;
	description: string | null;
	path: string | null;
	contextType: string | null;
};

export type AssetDTO = {
	id: string;
	parent: string | null;
	name: string | null;
	assetIcon: SystemIconDTO;
	headerImages: StoreImageDTO;
	companyLogo: StoreImageDTO;
	type: string | null;
	children: object[] | [];
	assetTags: SiteDropDownDTO[] | null;
	isCollection: boolean;
};

export type AssetDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: AssetDTO[] | null;
};

export type SiteDropDownDTO = {
	id: string;
	label: string | null;
	quickCode: string | null;
};

export type CreateAssetRequest = {
	parentId: string | null;
	name: string | null;
	assetType: SiteDropDownDTO;
	assetTags: SiteDropDownDTO[] | null;
	isCollection: boolean;
};

export type AssetDTOOperationResult = AssetDTOListOperationResult;

export type MulitSiteDropDownDTO = {
	id: string;
	label: string | null;
	children: object[] | [];
};

export type MulitSiteDropDownDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: MulitSiteDropDownDTO[];
};

export type DataRowControlOptionsDTO = {
	name: string | null;
	defaultValue: string | null;
	minValue: string | null;
	maxValue: string | null;
	controlWidth: number;
};

export type DataRowDTO = {
	id: string;
	fieldName: string | null;
	control: SiteDropDownDTO;
	controlOptions: DataRowControlOptionsDTO;
	value: object;
	dataLink: object;
	notes: string | null;
	orderID: number;
	required: boolean;
};

export type AssetTemplateSectionDTO = {
	id: string;
	name: string | null;
	fieldList: DataRowDTO[];
	orderID: number;
};

export type AssetWithTemplateDTO = {
	id: string;
	templateId: string;
	parent: AssetDTO;
	name: string | null;
	headerImages: StoreImageDTO;
	templateName: string | null;
	type: SiteDropDownDTO;
	templateIcon: SiteDropDownDTO;
	sections: AssetTemplateSectionDTO[] | null;
	assetTags: SiteDropDownDTO[] | null;
};

export type AssetWithTemplateDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: AssetWithTemplateDTO;
};

export type MoveAssetRequest = {
	assetId: string;
	newParentId: string | null;
	newCompanyId: string | null;
};

export type PrintAssetRequest = {
	assetId: string;
	reportId: string | null;
	fromDate: string | null;
	toDate: string | null;
	reportName: string | null;
};

export type TaskNoteItemDTO = {
	user: string | null;
	timestamp: string | null;
	note: string | null;
};

export type TaskMediaItemDTO = {
	media: string | null;
	url: string | null;
	name: string | null;
};

type TaskDetailsDTO = {
	sectionName: string | null;
	taskDetails: string | null;
	taskResult: string | null;
	taskNotes: TaskNoteItemDTO[] | null;
	taskMedia: TaskMediaItemDTO[] | null;
	taskVideos: TaskMediaItemDTO[] | null;
	signOffby: string | null;
	signOffDate: string | null;
	signOffLocation: string | null;
	signOffLocationRaw: string | null;
	signOffAltitude: string | null;
	signOffSigBase64: string | null;
};

export type JobSectionDetailsDTO = {
	name: string | null;
	result: string | null;
	signOffby: string | null;
	signOffDate: string | null;
	signOffLocation: string | null;
	singOffLocationRaw: string | null;
	signOffSigBase64: string | null;
	signOffResult: string | null;
	signOffTaskNotes: TaskNoteItemDTO[] | null;
	signOffTaskMedia: TaskMediaItemDTO[] | null;
	signOffTaskVideos: TaskMediaItemDTO[] | null;
	showCompliance: boolean;
	complianceSignOffby: string | null;
	complianceSignOffSigBash64: string | null;
	complianceResult: string | null;
	complianceTaskNotes: TaskNoteItemDTO[] | null;
	complianceTaskMedia: TaskMediaItemDTO[] | null;
	complianceTaskVideos: TaskMediaItemDTO[] | null;
	tasks: TaskDetailsDTO[] | null;
};

export type AssetSectionDetailsDTO = {
	assetName: string | null;
	locationName: string | null;
	jobs: JobSectionDetailsDTO[] | null;
};

export type InspAndMaintReportDTO = {
	reportName: string | null;
	reportDate: string | null;
	companyLogo: string | null;
	assets: AssetSectionDetailsDTO[] | null;
};

export type SiteTemplateSectionControlOptionsDTO = {
	name: string | null;
	defaultValue: string | null;
	minValue: string | null;
	maxValue: string | null;
	controlWidth: number;
};

export type DataRowControlDTO = {
	id: string;
	label: string | null;
	quickCode: string | null;
};

export type EditAssetRequest = {
	parent: AssetDTO;
	name: string | null;
	headerImages: StoreImageDTO | null;
	type: SiteDropDownDTO | null;
	templateName: string | null;
	templateIcon: SiteDropDownDTO | null;
	sections: AssetTemplateSectionDTO[] | null;
};

export type DeleteAssetRequest = object;

export type BooleanOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: boolean;
};

export type DisableAssetRequest = {
	assetId: string;
};

export type PatchRemoveFileFromAssetRequest = {
	assetId: string;
	fileId: string;
};

export type RefreshTokenRequest = {
	refreshToken: string | null;
};

export type AuthenticateRequest = {
	email: string;
	password: string;
};

export type StartPasswordRequest = {
	email: string | null;
};

export type ValidatePasswordResetRequest = {
	token: string | null;
	password: string | null;
	passwordConfirm: string | null;
};

export type SettingsGetListItemDTO = {
	id: string;
	name: string | null;
	lastEdited: string | null;
};

export type SettingsGetListItemDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: SettingsGetListItemDTO[];
};

export type CreateCompanyRequest = {
	companyName: string | null;
	headerImageId: string | null;
	companyLogoId: string | null;
	needConfirmation: boolean;
};

export type CompanyDTO = {
	id: string;
	name: string | null;
	description: string | null;
	logo: StoreImageDTO | null;
	headerImage: StoreImageDTO | null;
	displayOrderID: number;
	needConfirmation: boolean;
};

export type CompanyDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: CompanyDTO | null;
};

export type SiteDropDownDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: SiteDropDownDTO[];
};

export type GetAllCompaniesBySearchTextRequest = {
	searchText: string | null;
};

export type PatchCompanyRequest = {
	name: string | null;
	description: string | null;
	logo: StoreImageDTO | null;
	headerImage: StoreImageDTO | null;
	needConfirmation: boolean | null;
};

export type TemplateListViewDTO = {
	id: string;
	name: string | null;
	version: string | null;
	category: string | null;
	type: string | null;
	lastEdited: string | null;
};

export type TemplateListViewDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: TemplateListViewDTO[];
};

export type CreateAssetTemplateRequest = {
	templateName: string | null;
	templateIcon: SiteDropDownDTO | null;
	sections: AssetTemplateSectionDTO[];
};

export type SiteDropDownDTOSiteDropDownDTO = SiteDropDownDTO;

export type AssetTemplateDTO = {
	id: string;
	templateName: string | null;
	templateIcon: SiteDropDownDTOSiteDropDownDTO | null;
	sections: AssetTemplateSectionDTO[] | null;
};

export type AssetTemplateDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: AssetTemplateDTO | null;
};

export type GetAssetTemplatesBySearchDataRequest = {
	searchText: string | null;
};

export type PatchModifyTemplateRequest = {
	templateName: string | null;
	templateIcon: SiteDropDownDTO | null;
	sections: AssetTemplateSectionDTO[] | null;
};

export type MessageDTO = {
	id: string;
	senderId: string;
	name: string | null;
	message: string | null;
	sendTimeStamp: string | null;
	conversationId: string;
	profilePicture: string | null;
};

export type ConversationDTO = {
	id: string;
	name: string | null;
	messages: MessageDTO[];
	company: CompanyDTO;
	lastUpdate: string | null;
	unReadMessageCount: number;
	groupUserGroups: SiteDropDownDTO[];
	groupUsers: SiteDropDownDTO[];
	isMuted: boolean;
};

export type ConversationDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: ConversationDTO[];
};

export type CreateNewConversationRequest = {
	name: string | null;
	userGroups: SiteDropDownDTO[] | null;
	users: SiteDropDownDTO[] | null;
};

export type ModifyConversationRequest = {
	id: string;
	name: string | null;
	groupUserGroups: SiteDropDownDTOSiteDropDownDTO[];
	groupUsers: SiteDropDownDTOSiteDropDownDTO[];
};

export type ConversationDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: ConversationDTO | null;
};

export type MuteConversationRequest = {
	mute: boolean;
};

export type ArchiveConversationRequest = {
	conversationId: string;
};

export type HotSpotDTO = {
	id: string;
	name: string | null;
	coordinateX: number;
	coordinateY: number;
	type: string | null;
	value: object;
	orderId: number;
	nodes: HotSpotDTO[];
};

export type PatchHotspotTemplateRequest = {
	id: string | null;
	templateName: string | null;
	category: SiteDropDownDTO | null;
	topLevelName: string | null;
	topLevelImage: StoreImageDTO | null;
	nodes: HotSpotDTO[];
};

export type HotSpotTemplateDTO = {
	id: string;
	templateName: string | null;
	category: SiteDropDownDTO | null;
	topLevelName: string | null;
	topLevelImage: StoreImageDTO | null;
	nodes: HotSpotDTO[];
};

export type HotSpotTemplateDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: HotSpotTemplateDTO | null;
};

export type OperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
};

export type FileUploadResultDTO = {
	fileId: string;
	fileUrl: string | null;
	fileType: string | null;
	fileIcon: string | null;
	originalName: string | null;
	wasSuccess: boolean;
	lastError: string | null;
};

export type FileUploadResultDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: FileUploadResultDTO[];
};

export type JobTaskDTO = {
	id: string;
	taskName: string | null;
	status: string | null;
	taskLog: string | null;
	signOffBy: string | null;
	signOffTimeStamp: string | null;
	signOffLocation: string | null;
	lastUpdated: string | null;
	orderId: number | null;
	signOffBySigHash: string | null;
};

export type TaskNoteDTO = {
	id: string | null;
	data: string | null;
	type: string | null;
	noteTakenBy: string | null;
	lastUpdate: string | null;
	syncId: string | null;
};

export type SignOffDTO = {
	name: string | null;
	timestamp: string | null;
	sig: string | null;
	result: string | null;
	notes: TaskNoteDTO[] | null;
};

export type JobDTO = {
	asset: SiteDropDownDTOSiteDropDownDTO | null;
	userGroups: SiteDropDownDTOSiteDropDownDTO[];
	users: SiteDropDownDTOSiteDropDownDTO[];
	jobTemplate: SiteDropDownDTOSiteDropDownDTO | null;
	jobPeriodicity: SiteDropDownDTOSiteDropDownDTO | null;
	jobPriority: SiteDropDownDTOSiteDropDownDTO | null;
	signOffSkillLevel: SiteDropDownDTOSiteDropDownDTO | null;
	complianceSignOffSkillLevel: SiteDropDownDTOSiteDropDownDTO | null;
	jobTaskLists: JobTaskDTO[];
	startDate: string | null;
	completionDate: string | null;
	status: string | null;
	signOff: SignOffDTO;
	complianceSignOff: SignOffDTO;
};

export type JobDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: JobDTO | null;
};

export type SchedulingHeaderDTO = {
	id: string;
	headerName: string | null;
	rowCount: number;
	jobs: SchedulingJobRowDTO[];
};

export type SchedulingJobRow_UsergroupDTO = {
	name: string | null;
};

export type SchedulingJobRowDTO = {
	id: string;
	name: string | null;
	jobName: string | null;
	jobPeriodicityName: string | null;
	jobNumber: number | null;
	status: string | null;
	assigneesText: string | null;
	locationText: string | null;
	assetText: string | null;
	taskActive: number;
	taskInActive: number;
	taskPass: number;
	taskOther: number;
	taskFail: number;
	scheduleDate: string;
	completedDate: string | null;
	state: string | null;
	userGroups: SchedulingJobRow_UsergroupDTO[] | null;
};

export type CreateJobRequest = {
	assetID: string;
	userGroups: SiteDropDownDTO[];
	users: SiteDropDownDTO[];
	jobTemplate: string;
	jobPeriodicity: SiteDropDownDTO | null;
	jobPriority: SiteDropDownDTO | null;
	startDate: string | null;
	completionDate: string | null;
	signOffSkillLevel: SiteDropDownDTO | null;
	complianceSignOffSkillLevel: SiteDropDownDTO | null;
};

export type PatchScheduleReleaseAllRequest = {
	allJobs: boolean;
	jobId: string;
	groups: SiteDropDownDTO[];
	users: SiteDropDownDTO[];
};

export type PrintSingleJobRequest = {
	jobId: string;
};

export type CreateUserGroupRequest = {
	name: string | null;
};

export type UserGroupDTO = {
	id: string;
	name: string | null;
	lastEditedDate: string | null;
};

export type UserGroupDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: UserGroupDTO | null;
	nullable: boolean | null;
};

export type GetUserGroupsBySearchTextRequest = {
	searchText: string | null;
};

export type TreeviewItemDTO = {
	id: string | null;
	parentId: string | null;
	label: string | null;
	checked: boolean | null;
	type: string | null;
	assetTags: SiteDropDownDTO[] | null;
	nodes: TreeviewItemDTO[]; //added the type myself because it is not described in the Swagger
};

export type PostCompanyUserGroupTreeDropDownRequest = {
	permissionTree: TreeviewItemDTO[];
};

export type PatchUserGroupRequest = {
	id: string;
	name: string | null;
};

export type UserGroupDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: UserGroupDTO[];
};

export type JobReportDTO = {
	reportName: string;
	reportData: InspAndMaintReportDTO;
};

export type JobReportDTOOperationResult = {
	result: string;
	nullable?: boolean;
	message: string;
	callTime: string;
	type: string;
	data: JobReportDTO;
};

export type SiteDropDown = {
	id: string;
	label: string | null;
	quickCode: string | null;
};

export type UserTemplateDTO = {
	id: string;
	name: string | null;
	fieldList: DataRowDTO[];
};

export type CreateUserRequest = {
	firstname: string | null;
	lastName: string | null;
	email: string | null;
	phoneNumber: string | null;
	password: string | null;
	passwordConfirm: string | null;
	userType: SiteDropDownDTO;
	profilePictureId: string | null;
	userGroups: string[] | null;
	userSkills: string[] | null;
	userLocations: TreeviewItemDTO[];
	userTemplate: SiteDropDownDTO;
	customFieldTemplate: UserTemplateDTO;
};

export type UserDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: UserDTO;
};

export type UserListViewDTO = {
	id: string;
	companyHeaderImage: string | null;
	profilePictureUrl: string | null;
	name: string | null;
	infoLine: string | null;
};

export type GetUserBySearchTextRequest = {
	searchText: string | null;
};

export type UserListViewDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: UserListViewDTO[] | null;
};

export type UserDTO = {
	id: string;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phoneNumber: string | null;
	profilePicture: StoreImageDTO;
	inviteCode: string | null;
	company: CompanyDTO[];
	userType: SiteDropDownDTO;
	userGroups: SiteDropDownDTO[];
	userSkills: SiteDropDownDTO[];
	userLocations: TreeviewItemDTO[];
	userTemplate: SiteDropDownDTO;
	customDataTemplate: object | null;
	allowAssetPermissionEdit: boolean;
};

export type PatchUserRequest = {
	id: string;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phoneNumber: string | null;
	password: string | null;
	passwordConfirm: string | null;
	profilePictureId: string | null;
	inviteCode: string | null;
	userType: SiteDropDownDTO;
	userGroups: SiteDropDownDTO[];
	userSkills: SiteDropDownDTO[];
	userLocations: TreeviewItemDTO[];
	userTemplate: SiteDropDownDTO;
	customDataTemplate: UserTemplateDTO;
};

export type TreeviewItemDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: TreeviewItemDTO[];
};

export type PrintUserRequest = {
	userId: string;
	reportId: string;
	from: string | null;
	to: string | null;
};

export type GetUsersByUserGroupsRequest = {
	usergroups: SiteDropDownDTO[];
};

export type PatchUserDisableRequest = {
	userId: string;
};

export type GetUserGPSHeatMapRequest = {
	userId: string;
	startDate: string;
	endDate: string;
	includeSyncMarker: boolean;
};

export type GoogleMapMarkMarkerDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: GoogleMapMarkMarkerDTO;
};

export type GoogleMapMarkMarkerDTO = {
	userId: string;
	displayName: string | null;
	markers: GPSMarkerDTO[] | null;
};

export enum UserGpsAction {
	Started = 'Started',
	AddNote = 'AddNote',
	AddMedia = 'AddMedia',
	FinishedWithPass = 'FinishedWithPass',
	FinishedWithOther = 'FinishedWithOther',
	FinishedWithFailed = 'FinishedWithFailed',
	SignOff = 'SignOff',
}

export type GPSMarkerDTO = {
	lat: string | null;
	lng: string | null;
	att: string | null;
	acc: string | null;
	spd: string | null;
	timeStamp: string | null;
	action: UserGpsAction;
	taskId: string | null;
	taskName: string | null;
	taskNotes: string | null;
	jobId: string | null;
	jobName: string | null;
};

export type CreateUserTemplateRequest = {
	templateName: string | null;
	fieldList: DataRowDTO[];
};

export type UserTemplateDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: UserTemplateDTO;
};

export type GetUserTemplateBySearchRequest = {
	searchText: string | null;
};

export type UserTemplateDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: UserTemplateDTO[];
};

export type PatchUserTemplateRequest = {
	name: string | null;
	templateIcon: string;
	fieldList: DataRowDTO[];
};

export type DeleteUserTemplateRequest = {
	id: string;
};

export type CreateUserSkillRequest = {
	name: string | null;
};

export type SettingsGetListItemDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: SettingsGetListItemDTO;
};

export type UserSkillDTO = {
	id: string;
	name: string | null;
	lastEditedDate: string | null;
};

export type GetUserSkillsBySearchTextRequest = {
	searchText: string | null;
};

export type PostCompanyUserSkillsTreeDropDownRequest = {
	permissionTree: TreeviewItemDTO[];
};

export type PatchUserSkillRequest = {
	name: string | null;
};

export type DeleteUserSkillRequest = object;

export type PagePermissionGroupDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: PagePermissionGroupDTO[];
};

export type PagePermissionGroupDTO = {
	id: string;
	isGroup: boolean;
	isOverridden: boolean;
	name: string | null;
	permissions: PagePermissionsDTO[];
};

export type PagePermissionsDTO = {
	name: string | null;
	access: boolean;
};

export type MenuPermissionDTO = {
	name: string | null;
	accessPath: string | null;
	iconName: string | null;
	orderId: number | null;
	permissions: PagePermissionsDTO[] | null;
};

export type FetchPagePermissionsRequest = {
	pageAccessId: string;
	userGroupsId: string[];
};

export type PatchUserPermissionRequest = {
	screenId: string;
	userGroupId: string[];
	permissions: PagePermissionGroupDTO[];
};

export type CreateJobTemplateRequest = {
	templateName: string | null;
	category: SiteDropDownDTO;
	sections: JobTemplateSectionRO[];
};

export type CreateCMSAdhocJobTemplateRequest = {
	templateName: string | null;
	sections: JobTemplateSectionRO[];
};

export type JobTemplateSectionRO = {
	id: string;
	name: string | null;
	orderID: number;
	checkItems: JobTemplateCheckListItemRO[];
};

export type JobTemplateCheckListItemRO = {
	id: string;
	taskName: string | null;
	taskNote: string | null;
	orderID: number;
	checkItemSkills: SiteDropDownDTO[];
};

export type JobTemplateDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: JobTemplateDTO;
};

export type JobTemplateDTO = {
	id: string;
	templateName: string | null;
	category: SiteDropDownDTO;
	sections: JobTemplateSectionDTO[];
};

export type JobTemplateSectionDTO = {
	id: string;
	name: string | null;
	orderID: number;
	checkItems: JobTemplateCheckListItemDTO[];
};

export type JobTemplateCheckListItemDTO = {
	id: string;
	taskName: string | null;
	taskNote: string | null;
	orderID: number;
	checkItemSkills: SiteDropDownDTO[];
};

export type PatchJobTemplateRequest = {
	templateName: string | null;
	category: SiteDropDownDTO;
	sections: JobTemplateSectionRO[];
};

export type GetJobTemplateScheduleViewRequest = {
	searchText: string | null;
};

export type JobTemplateCategoryHeaderDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: JobTemplateCategoryHeaderDTO[];
};

export type JobTemplateCategoryHeaderDTO = {
	id: string;
	name: string | null;
	templates: JobTemplateAccordViewDTO[];
};

export type JobTemplateAccordViewDTO = {
	id: string;
	name: string | null;
	type: string | null;
	category: string | null;
	version: string | null;
	lastEdited: string | null;
};

export type JobTemplateCategoryDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: JobTemplateCategoryDTO[];
};

export type JobTemplateCategoryDTO = {
	id: string;
	name: string | null;
	isTraining: boolean;
	display: boolean;
	lastEdited: string | null;
};

export type CreateJobTemplateCategoryRequest = {
	name: string | null;
	isTraining: boolean;
	display: boolean;
};

export type JobTemplateCategoryDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: JobTemplateCategoryDTO;
};

export type GetJobTemplateCategoryBySearchRequest = {
	searchText: string | null;
};

export type PatchJobTemplateCategoryRequest = {
	name: string | null;
	isTraining: boolean;
	display: boolean;
};

export type GetAllUserNotificationsRequest = {
	showSystem: boolean;
	showUser: boolean;
	pastDays: number;
};

export type NotificationDTO = {
	id: string;
	msg: string | null;
	sentDate: string | null;
	isRead: boolean;
};

export type NotificationGroupDTO = {
	groupName: string | null;
	notifications: NotificationDTO[];
};

export type NotificationGroupDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: NotificationGroupDTO[] | null;
};

export type NotificationCountDTO = {
	totalRows: number;
	notNotifiedRows: number;
};

export type NotificationCountDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: NotificationCountDTO;
};

export type GetUserNotificationsRequest = {
	isRead: boolean;
	pageSize: number | null;
	page: number | null;
};

export type NotificationPageDTO = {
	rowCount: number;
	notifications: NotificationDTO | null;
};

export type NotificationDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: NotificationPageDTO[] | null;
};

export type PatchMarkAsReadRequest = {
	notificationMsgId: string;
};

export type PatchMarkAllReadRequest = object;

export enum OrderDirection {
	Asc = 'Asc',
	Desc = 'Desc',
}

export enum ScheduleState {
	Active = 'Active',
	Overdue = 'Overdue',
	Upcoming = 'Upcoming',
	Completed = 'Completed',
	AdHoc = 'AdHoc',
	WaitingSignOff = 'WaitingSignOff',
	WaitingCompliance = 'WaitingCompliance',
}

export type OrderOptions = {
	property: string | null;
	direction: OrderDirection;
};

export type FetchScheduleJobsListByFilterRequest = {
	orderBy: OrderOptions[];
	pageSize: number | null;
	page: number | null;
	searchText: string | null;
	dateFrom: string | null;
	dateTo: string | null;
	states: ScheduleState[] | null;
};

export type SchedulingHeaderDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: SchedulingHeaderDTO;
};

export enum JobChangeStatus_ScheduleStatus {
	WaitingToStart = 'WaitingToStart',
	Cancelled = 'Cancelled',
	Pass = 'Pass',
	Other = 'Other',
	Failed = 'Failed',
}

export type PatchJobChangeStatusRequest = {
	status: JobChangeStatus_ScheduleStatus;
	reason: string | null;
};

export enum AuditLogGroup {
	All = 'All',
	Ride = 'Ride',
	Template = 'Template',
	Job = 'Job',
}

export type FetchAuditLogBySearchRequest = {
	searchText: string | null;
	userName: string | null;
	fromDate: string | null;
	toDate: string | null;
	group: AuditLogGroup;
	pageSize: number | null;
	page: number | null;
};

export type AuditLogDTO = {
	text: string | null;
	actionDate: string | null;
};

export type AuditLogsDTO = {
	auditLogs: AuditLogDTO[] | null;
	rowCount: number | null;
};

export type AuditLogsDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: AuditLogsDTO | null;
};

export type AuthCompanyDTO = {
	id: string;
	name: string | null;
	messageCount: number;
	selected: boolean;
	logo: string | null;
	brandColor: string | null;
	needComplianceCheck: boolean;
};

export type AuthenticateDTO = {
	id: string;
	firstName: string | null;
	lastName: string | null;
	username: string | null;
	companyList: AuthCompanyDTO[] | null;
	companyLogoUrl: string | null;
	companyColours: string | null;
	menuPermissions: MenuPermissionDTO[] | null;
	extendedPermissions: PagePermissionsDTO[] | null;
	jwtToken: string | null;
	refreshToken: string | null;
	refreshTokenExpire: number;
	hasDirectorOverride: boolean;
	apiVersion: string | null;
	userPermissions: string | null;
	companyNeedsCompliance: boolean;
	userGroups: string | null;
	isAdmin: boolean;
	notificationCount: number;
};

export type SnoozeNotificationRequest = { hours: number };

export type PostAssignedCompanyUserGroupTreeDropDownRequest = { companyIds: string[] | null };

export type CreateAssetTagRequest = {
	name: string | null;
};

export type PatchAssetTagRequest = {
	name: string | null;
};

export type DeleteAssetTagRequest = object;

export type GetAssetTagBySearchTextRequest = {
	searchText: string | null;
};

export type WidgetDTOListOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: WidgetDTO[] | null;
};

export type WidgetDTO = {
	id: string;
	name: string | null;
	defaultWidth: number;
	defaultHeight: number;
	minWidth: number;
	maxWidth: number;
	minHeight: number;
	checked: boolean;
	x: number;
	y: number;
	w: number;
	h: number;
	customData: string | null;
};

export interface WidgetDTOExtended extends WidgetDTO {
	component: string;
	i: string;
	isVisible: boolean;
	label: string;
}

export type DashboardLocationStatusDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: DashboardLocationStatusDTO[] | null;
};

export type DashboardLocationStatusDTO = {
	locationName: string | null;
	assetRoots: DashboardAssetRootDTO[] | null;
};

export type DashboardAssetRootDTO = {
	id: string;
	name: string | null;
	incompleteJobs: number;
	locationCategory: LocationCategory;
};

export enum LocationCategory {
	Green,
	Amber,
	Red,
}

export type DashboardActiveUsersDTOOperationResult = {
	result: string | null;
	message: string | null;
	callTime: string | null;
	type: string | null;
	data: DashboardActiveUsersDTO[] | null;
};

export type DashboardActiveUsersDTO = {
	summary: UserActiveLoginDTO;
	active: UserActiveLoginDTO;
	inactive: UserActiveLoginDTO;
	lastTimescale: UserActiveLoginDTO;
};

export type UserActiveLoginDTO = {
	cms: number;
	app: number;
};

export type GetUsersActiveLoginRequest = {
	lastOnlineMinutes: number;
};
