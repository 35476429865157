import { postAuth } from '@/api/auth';

const state = () => ({
	user: {},
	currentCompany: '',
	newCompanyMessages: 0,
	routerViewKey: 0,
	companyNeedCompliance: false,
});

const mutations = {
	updateUser(state, data) {
		state.user = data;
	},
	updateCompany(state, data) {
		state.currentCompany = data;
		state.companyNeedCompliance = data.needComplianceCheck;
		state.newCompanyMessages = 0;
	},
	setRouterViewKey(state, value) {
		state.routerViewKey = value;
	},
	updateNewCompanyMessages(state, newMessages) {
		state.newCompanyMessages = Math.max(0, state.newCompanyMessages + newMessages);
	},
	resetNewCompanyMessages(state) {
		state.newCompanyMessages = 0;
	},
	updateCheckNotification: (state, data) => {
		state.user.notificationCount = data.totalRows;
	},
	decreaseNotificationsCount: (state) => {
		state.user.notificationCount = state.user.notificationCount - 1;
	},
};

const actions = {
	updateUser(context, data) {
		context.commit('updateUser', data);
	},
	async updateUsersData(context) {
		const refreshToken = localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9;
		const token = localStorage.c5ad83ab73e67307a1af0d28ae97fdc4;

		const urlArray = window.location.href.split('/');
		let currentURL = '';
		for (let i = 3; i < urlArray.length; i++) {
			currentURL = currentURL + urlArray[i] + '??slash??';
		}

		if (token) {
			try {
				const { data } = await postAuth({ refreshToken });

				context.commit('updateUser', data);
				localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = data.jwtToken;
				localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = data.refreshToken;
			} catch (err) {
				console.log('Err', err);
				localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = '';
				localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = '';
			}
		} else {
			localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = '';
			localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = '';
		}
	},
	updateRouterViewKey(context, value) {
		context.commit('setRouterViewKey', value);
	},
};

const getters = {
	getIsUserAdmin: (state) => {
		return state.user.isAdmin;
	},
	getUserExtendedPermissions: (state) => {
		return state.user.extendedPermissions?.reduce((acc, permission) => {
			return { ...acc, [permission.name]: permission.access };
		}, {});
	},
	getUserPagePermissions: (state) => (pageName) => {
		return (
			state.user?.menuPermissions
				.find((page) => page?.name.toLowerCase() === pageName?.toLowerCase())
				?.permissions.reduce((acc, permission) => {
					return { ...acc, [permission?.name.toLowerCase()]: permission.access };
				}, {}) || {}
		);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
