const state = () => ({
	id: '',
	assetId: '',
	toDate: '',
	reportId: '',
	reportName: '',
	fromDate: '',
	type: '',
});

const mutations = {
	setReport(state, data) {
		state.id = data.value.id;
		state.assetId = data.value.assetId;
		state.toDate = data.value.toDate;
		state.reportId = data.value.reportId;
		state.fromDate = data.value.fromDate;
		state.reportName = data.value.reportName;
		state.type = data.value.type;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
};
