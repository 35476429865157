const state = () => ({
	loaded: false,
	data: null,
	defaultData: [
		{
			id: 1,
			label: 'User 1',
		},
		{
			id: 2,
			label: 'User 2',
		},
		{
			id: 3,
			label: 'User 3',
		},
		{
			id: 4,
			label: 'User 4',
		},
		{
			id: 5,
			label: 'User 5',
		},
		{
			id: 6,
			label: 'User 6',
		},
	],
});

const mutations = {
	getData(state) {
		state.data = state.defaultData;

		state.loaded = true;
	},
	destroyData(state) {
		state.data = null;
		state.loaded = false;
	},
};

// actions
const actions = {
	getData(context) {
		context.commit('getData');
	},
	destroyData(context) {
		context.commit('destroyData');
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
