import { AxiosRequestConfig } from 'axios';
import { api } from './index';
import {
	AuthenticateDTO,
	AuthenticateRequest,
	BooleanOperationResult,
	RefreshTokenRequest,
	StartPasswordRequest,
	ValidatePasswordResetRequest,
} from '@/types';

export const postAuth = (data: RefreshTokenRequest) => api.post<AuthenticateDTO>('/api/auth', data);

export const postAuthFetchToken = (data: AuthenticateRequest) => api.post<void>('/api/auth/fetchToken', data);

export const switchCompanyById = (companyId: string) => api.post<void>(`/api/auth/switchCompany/${companyId}`);

export const postAuthReset = (data: StartPasswordRequest, config: AxiosRequestConfig = {}) =>
	api.post<BooleanOperationResult>('/api/auth/reset', data, config);

export const postAuthValidate = (data: ValidatePasswordResetRequest, config: AxiosRequestConfig = {}) =>
	api.post<BooleanOperationResult>('/api/auth/validate', data, config);
