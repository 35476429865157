import { createApp } from 'vue';
import App from '@/App.vue';
import '@/functions/validation/validationRules';
import router from '@/_router';
import store from '@/_store';

import 'bootstrap';
import './assets/scss/index.scss';

const app = createApp(App);

app.directive('focus', {
	mounted(el, binding) {
		if (binding.value === undefined || binding.value === true) {
			el.focus();
		}
	},
	updated(el, binding) {
		if (binding.value === undefined || binding.value === true) {
			el.focus();
		}
	},
});

app.use(router).use(store).mount('#app');
