import { defineRule } from 'vee-validate';
import { required, email, image } from '@vee-validate/rules';

const REGEX_DIGITS_ONLY = /^\d+$/;

defineRule('required', (value: unknown) => {
	if (!required(value)) {
		return 'This field is required';
	}
	return true;
});

defineRule('minLength', (value: string, [limit]: number[]): boolean | string => {
	if (!value) {
		return true;
	}
	if (value.length < limit) {
		return `This field must be at least ${limit} characters`;
	}
	return true;
});

defineRule('email', (value: string) => {
	if (!value) {
		return true;
	}
	if (!email(value)) {
		return 'This field must be a valid email';
	}
	return true;
});

defineRule('image', (value: File) => {
	if (!value) {
		return true;
	}
	if (!image(value)) {
		return 'It must be an image.';
	}
	const fileType = value.type;
	const fileExtensions = ['JPG', 'JPEG', 'WebP', 'PNG', 'GIF', 'BMP', 'TIFF'];
	const imageTypes = fileExtensions.map((type) => `image/${type.toLocaleLowerCase()}`);

	if (!imageTypes.includes(fileType)) {
		return `Image must be of ${fileExtensions.join(', ')} type.`;
	}
	return true;
});

defineRule('anyImage', (value: File) => {
	if (!value) {
		return true;
	}
	if (!image(value)) {
		return 'It must be an image.';
	}
	return true;
});

defineRule('dimensions', (value: File, [width, height]: [number, number]) => {
	return new Promise<string | boolean>((resolve) => {
		const image = new Image();
		image.src = URL.createObjectURL(value);
		image.onload = () => {
			if (image.width > width || image.height > height) {
				resolve(true);
			} else {
				resolve(`Image must be at least ${width}x${height}px instead of ${image.width}x${image.height}px`);
			}
			URL.revokeObjectURL(image.src);
		};
		image.onerror = () => {
			resolve('An error occurred while loading the image');
		};
	});
});

defineRule('digits', (value: string, [length]: [string]): boolean | string => {
	if (!value) {
		return true;
	}

	const len = parseInt(length, 10);
	const regex = new RegExp(`^\\d{${len}}$`);
	if (!regex.test(value)) {
		return `This field must contain exactly ${len} digits.`;
	}
	return true;
});

defineRule('min_digits', (value: string, [length]: [string]): boolean | string => {
	if (!value) {
		return true;
	}

	const len = parseInt(length, 10);
	const regex = /\d/g;
	const matchedDigits = value.match(regex) || [];

	if (matchedDigits.length < len) {
		return `This field must contain at least ${len} digit${len > 1 ? 's' : ''}.`;
	}
	return true;
});

defineRule('digits_between', (value: string, [from, to]: number[]): boolean | string => {
	if (!value) {
		return true;
	}

	if (!REGEX_DIGITS_ONLY.test(value)) {
		return `This field must contain only digits`;
	}

	const valueLen = value.length;

	if (valueLen < from || valueLen > to) {
		return `The field must contain ${from} to ${to} digits`;
	}

	return true;
});

defineRule('min_value', (value: string, [min]: [string]): boolean | string => {
	if (!value) {
		return true;
	}

	if (value < min) {
		return `The field value must not be less than ${min}.`;
	}
	return true;
});

defineRule('confirmPassword', (value: string, [target]: [string]): boolean | string => {
	if (value === target || (!target && !value)) {
		return true;
	}
	return 'Passwords must match.';
});

defineRule('not_one_of', (value: string, [...rest]: string[]): boolean | string => {
	let result: boolean | string = true;
	if (!value) {
		return result;
	}
	const dataToCheck = value.split('');
	rest.forEach((character) => {
		if (dataToCheck.includes(character)) {
			result = `The field must not include '${character}'.`;
		}
	});

	return result;
});
