import { AxiosRequestConfig } from 'axios';
import { api } from './index';
import {
	AuditLogsDTOOperationResult,
	BooleanOperationResult,
	FetchAuditLogBySearchRequest,
	GetAllUserNotificationsRequest,
	GetUserNotificationsRequest,
	NotificationCountDTOOperationResult,
	NotificationDTOListOperationResult,
	NotificationGroupDTOListOperationResult,
	PatchMarkAllReadRequest,
	PatchMarkAsReadRequest,
	SnoozeNotificationRequest,
} from '@/types';

export const createNotification = (notification: GetAllUserNotificationsRequest, config: AxiosRequestConfig = {}) =>
	api.post<NotificationGroupDTOListOperationResult>('/api/notification', notification, config);

export const getGroupNotifications = (date: GetUserNotificationsRequest) =>
	api.post<NotificationDTOListOperationResult>('/api/notification', date);

export const searchNotificationAuditLog = (search: FetchAuditLogBySearchRequest, config: AxiosRequestConfig = {}) =>
	api.post<AuditLogsDTOOperationResult>('/api/notification/auditLog', search, config);

export const updateNotification = (
	msgId: string,
	notification: PatchMarkAsReadRequest,
	config: AxiosRequestConfig = {},
) => api.patch<NotificationGroupDTOListOperationResult>(`/api/notification/${msgId}`, notification, config);

export const snoozeNotification = (msgId: string, data: SnoozeNotificationRequest, config: AxiosRequestConfig = {}) =>
	api.patch<BooleanOperationResult>(`/api/notification/${msgId}/snooze`, data, config);

export const clearNotifications = (data: PatchMarkAllReadRequest, config: AxiosRequestConfig = {}) =>
	api.patch<NotificationGroupDTOListOperationResult>('/api/notification/clear', data, config);

export const getNotificationsUnreadCount = (config: AxiosRequestConfig = {}) =>
	api.get<NotificationCountDTOOperationResult>('/api/notification/unread/count', config);
