import router from '@/_router';

const state = () => ({
	expanded: false,
	currentPage: '',
	pageTitle: '',
	logoURL: '',
	menuList: [
		{
			id: 1,
			name: 'Dashboard',
			icon: 'fas fa-chart-bar',
			link: 'Dashboard',
		},
		{
			id: 2,
			name: 'Schedule Centre',
			icon: 'far fa-calendar-alt',
			link: 'scheduleCenter',
		},
		{
			id: 3,
			name: 'Asset Manager',
			icon: 'fas fa-cubes',
			link: 'assetManager',
		},
		{
			id: 4,
			name: 'User Manager',
			icon: 'fas fa-users',
			link: 'userManager',
		},
		{
			id: 5,
			name: 'Settings',
			icon: 'fas fa-cog',
			link: 'settings',
		},
	],
});

const mutations = {
	getCurrentPage(state, data) {
		const currentPage = '/' + window.location.href.split('/')[3].split('?')[0];

		if (currentPage !== '/') {
			const allRoutes = router.getRoutes();

			for (let i = 0; i < allRoutes.length; i++) {
				if (allRoutes[i].path === currentPage) {
					state.currentPage = allRoutes[i].name;
				}
			}
			state.pageTitle = data;
		} else {
			state.currentPage = 'Dashboard';
			state.pageTitle = data;
		}
	},
	sidebarToggle(state) {
		state.expanded = !state.expanded;
	},
	updatePageTitle(state, newTitle) {
		state.pageTitle = newTitle;
	},
};

// actions
const actions = {
	getCurrentPage(context, data) {
		context.commit('getCurrentPage', data);
	},
	sidebarToggle(context) {
		context.commit('sidebarToggle');
	},
	setPageTitle(context, newTitle) {
		context.commit('updatePageTitle', newTitle);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
