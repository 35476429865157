<template>
	<div class="loginPopup">
		<div class="inner">
			<div class="title">
				<h2>Re-Login</h2>
			</div>
			<form @submit.prevent="formSubmition()">
				<div class="credentials">
					<label for="email">Email Address:</label>
					<input type="text" name="email" id="email" placeholder="Enter email address..." v-model="form.email" />
					<label for="password">Password:</label>
					<input
						type="password"
						name="password"
						id="password"
						placeholder="Enter password..."
						v-model="form.password"
					/>
					<button>LOGIN</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import store from '../../_store';
import { createToast } from 'mosha-vue-toastify';

import { formValidationCheck } from '../../functions/validation';

export default {
	name: 'authPopup',
	components: {},
	setup() {
		const error = ref(false);
		const form = ref({
			email: '',
			password: '',
		});

		const formSubmition = () => {
			error.value = false;
			errorCheck();
			if (!error.value) {
				axios({
					method: 'POST',
					url: store.state.api + '/api/Auth/fetchToken',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.c5ad83ab73e67307a1af0d28ae97fdc4}`,
					},
					data: form.value,
				})
					.then((response) => {
						store.commit('currentUser/updateUser', response.data);
						console.log('axios >>>>>>>>', response.data);

						localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = response.data.jwtToken;
						localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = response.data.refreshToken;

						const message = 'You have successfully re-logged in';
						const type = 'success';
						createToast(message, { type: type, showIcon: true, position: 'bottom-right' });

						store.commit('authPopup/hidePopup');
					})
					.catch(() => {
						window.location.reload();
					});
			}
		};
		const errorCheck = () => {
			const validationChecksArray = [
				{
					description: 'Please enter your email address',
					value: form.value.email,
				},
				{
					description: 'Please enter your password',
					value: form.value.password,
				},
			];
			for (let i = 0; i < validationChecksArray.length; i++) {
				if (!error.value) {
					if (!formValidationCheck(null, validationChecksArray[i].description, null, validationChecksArray[i].value)) {
						error.value = true;
					}
				}
			}
		};

		return {
			form,

			formSubmition,
		};
	},
};
</script>

<style lang="scss" scoped>
.loginPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 14;

	.inner {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 50%;
		max-width: 800px;
		min-width: 400px;
		height: fit-content;
		background-color: var(--background);
		border-radius: 15px;
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
		z-index: 1;
		padding: 33px;
		.title {
			margin-bottom: 45px;
			h2 {
				text-align: center;
				font-size: 26px;
				font-weight: 800;
				color: var(--cta);
			}
		}
		.credentials {
			display: flex;
			flex-direction: column;
			label {
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 5px;
			}
			input {
				margin-bottom: 15px;
				width: 100%;
				border-radius: 10px;
				outline: none;
				border: 1px solid grey;
				padding: 5px 15px;
			}
			button {
				margin-top: 30px;
				width: 100%;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
}
</style>
