import getEnvVariable from '@/utils/getEnvVariable';

import { AuditLogGroup, JobChangeStatus_ScheduleStatus, SelectOption, UserGpsAction } from '@/types';

export const API_BASE_URL = getEnvVariable('API_BASE_URL');
export const API_CHAT_URL = getEnvVariable('API_CHAT_URL');
export const API_REPORT_URL = getEnvVariable('API_REPORT_URL');
export const GOOGLE_MAP_API_KEY = getEnvVariable('GOOGLE_MAP_API_KEY');
export const STIMULSOFT_LICENSE_KEY = getEnvVariable('STIMULSOFT_LICENSE_KEY');
export const DASHBOARD_UPDATE_TIMEOUT = 10;

export const jobStatusesOptions: SelectOption[] = [
	{ label: 'Pending', value: JobChangeStatus_ScheduleStatus.WaitingToStart },
	{ label: 'Cancelled Job', value: JobChangeStatus_ScheduleStatus.Cancelled },
	{ label: 'Passed', value: JobChangeStatus_ScheduleStatus.Pass },
	{ label: 'Issue reported', value: JobChangeStatus_ScheduleStatus.Other },
	{ label: JobChangeStatus_ScheduleStatus.Failed, value: JobChangeStatus_ScheduleStatus.Failed },
];

export const auditLogGroupsOptions: SelectOption[] = Object.values(AuditLogGroup).map((group) => ({
	value: group,
	label: group,
}));

export const MIN_PERIOD_FOR_ALERT = 1;
export const periodTypeOptions = ['Day', 'Week', 'Month', 'Year']; //options for vSelect
export const alertTypeOptions = ['Once', 'Periodic']; //options for vSelect

export const settingsSections = [
	{
		id: 1,
		name: 'Company',
	},
	{
		id: 9,
		name: 'CMS Permissions',
	},
	{
		splitter: true,
	},
	/*{
		id: 2,
		name: "Dashboard Templates",
	},*/
	{
		id: 3,
		name: 'Job Templates',
	},
	{
		id: 4,
		name: 'Asset Templates',
	},
	{
		id: 10,
		name: 'Asset Tags',
	},
	{
		id: 5,
		name: 'User Templates',
	},
	{
		id: 8,
		name: 'Template Categories',
	},
	{
		splitter: true,
	},
	{
		id: 6,
		name: 'User Groups',
	},
	{
		id: 7,
		name: 'User Skills',
	},
];

export const grayscale = [
	'#FFFFFF',
	'#F7F7F7',
	'#EFEFEF',
	'#E7E7E7',
	'#DFDFDF',
	'#D7D7D7',
	'#CFCFCF',
	'#C7C7C7',
	'#BFBFBF',
	'#B7B7B7',
	'#AFAFAF',
	'#A7A7A7',
	'#9F9F9F',
	'#979797',
	'#8F8F8F',
	'#878787',
	'#7F7F7F',
	'#777777',
];

export const jobActionsClasses: Record<UserGpsAction, string> = {
	Started: 'started',
	AddNote: 'add-note',
	AddMedia: 'add-media',
	FinishedWithPass: 'finished-with-pass',
	FinishedWithOther: 'finished-with-other',
	FinishedWithFailed: 'finished-with-failed',
	SignOff: 'sign-off',
};
