<template>
	<topbar v-if="pageTitle" />
	<sidebar v-if="pageTitle" />
	<alertPopup v-if="store.state.alert.currentAlert.message !== ''" :message="message" />
	<authPopup v-if="store.state.authPopup.show" />
	<div :class="{ page: pageTitle, customPage: isRootPath }">
		<router-view :key="routerViewKey" />
	</div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { createToast } from 'mosha-vue-toastify';

import sidebar from '@/components/_layouts/sidebar';
import topbar from '@/components/_layouts/topbar';
import alertPopup from '@/components/popup/alerts';
import authPopup from '@/components/popup/auth';

import showResponseMessage from '@/functions/validation/showResponseMessage';

import { getNotificationsUnreadCount } from '@/api/notification';

export default {
	name: 'App',
	components: {
		sidebar,
		topbar,
		alertPopup,
		authPopup,
	},

	setup() {
		const store = useStore();
		const route = useRoute();
		const isRootPath = computed(() => route.path === '/');

		const pageTitle = ref(computed(() => store.state.sidebar.pageTitle));
		const message = ref(computed(() => store.state.alert.currentAlert.message));
		const routerViewKey = computed(() => store.state.currentUser.routerViewKey);
		const currentUserId = computed(() => store.state.currentUser.user.id);

		let interval = null;

		const messageReceived = (data) => {
			if (data.senderId !== currentUserId.value) {
				store.commit('currentUser/updateNewCompanyMessages', 1);
			}
		};

		const changeRouterViewKey = () => {
			const newValue = store.state.currentUser.routerViewKey + 1;
			store.dispatch('currentUser/updateRouterViewKey', newValue);
		};

		const showNewAlertsInformMessage = (notesByPeriod) => {
			const description = `You have ${notesByPeriod > 1 ? notesByPeriod : ''} new ${
				notesByPeriod > 1 ? 'alerts' : 'alert'
			}`;
			createToast(
				{ description },
				{
					type: 'default',
					hideProgressBar: true,
					transition: 'slide',
					showIcon: true,
					position: 'bottom-right',
				},
			);
		};

		const getNewNotificationInfo = async () => {
			if (document.visibilityState === 'visible') {
				try {
					const response = await getNotificationsUnreadCount();

					if (response.data.result && response.data.result.toLowerCase() === 'success') {
						const notificationInfo = response.data.data;
						const notesByPeriod = notificationInfo.notNotifiedRows;

						if (notesByPeriod) {
							showNewAlertsInformMessage(notesByPeriod);
							store.commit('currentUser/updateCheckNotification', notificationInfo);

							if (route.name === 'notificationCenter') {
								changeRouterViewKey();
							}
						}
					} else {
						showResponseMessage(response);
					}
				} catch (error) {
					console.log(error);
				}
			}
		};

		onMounted(async () => {
			getNewNotificationInfo();
			interval = setInterval(() => {
				getNewNotificationInfo();
			}, 60000);
		});

		onUnmounted(() => {
			clearInterval(interval);
		});

		return {
			pageTitle,
			message,
			store,
			routerViewKey,
			isRootPath,
		};
	},
};
</script>
<style lang="scss">
.row {
	h2,
	p {
		height: auto;
	}
}
</style>
