<template>
	<div class="pageTitle">
		<h1 v-if="pageTitle.length < 50">{{ pageTitle }}</h1>
		<h1 v-else>{{ pageTitle.slice(0, 50) }}...</h1>
	</div>
	<div class="comp-topbar" :class="{ active: dropDownMenu }">
		<div
			class="userProfile position-relative"
			:class="{ alert: currentCompany.notificationCount > 0 }"
			@click="dropDownToggle()"
		>
			<span>{{ currentUser.firstName.slice(0, 1) }}{{ currentUser.lastName.slice(0, 1) }}</span>

			<div @click.stop.prevent class="position-absolute translate-middle badge text-black notification-icons">
				<router-link :to="{ name: 'notificationCenter' }">
					<span class="position-relative">
						<i class="fas fa-bell icon-outline me-3"></i>
						<span
							v-if="currentUser.notificationCount"
							class="position-absolute start-50 translate-middle badge rounded-pill bg-danger notification"
						>
							{{ currentUser.notificationCount }}
							<span class="visually-hidden">unread messages</span>
						</span>
					</span>
				</router-link>
				<router-link :to="{ name: 'messageCenter' }">
					<span class="position-relative">
						<i class="fas fa-envelope icon-outline"></i>
						<span
							v-if="currentCompany.messageCount + newCompanyMessages"
							class="position-absolute start-100 translate-middle badge rounded-pill bg-danger notification"
						>
							{{ currentCompany.messageCount + newCompanyMessages }}
							<span class="visually-hidden">unread alerts</span>
						</span>
					</span>
				</router-link>
			</div>
		</div>
		<div class="dropdown" :class="{ open: dropDownMenu }">
			<div class="links">
				<div class="linkGroup">
					<a class="current">
						<p class="pb-0">
							<strong>{{ currentUser.firstName }} {{ currentUser.lastName }}</strong>
						</p>
						<p class="pt-0">
							Viewing <span>{{ currentCompany.name }}</span>
						</p>
					</a>
				</div>
				<div
					class="linkGroup"
					v-for="group in list"
					:key="group.id"
					:class="{
						noBorder: group.type === 'companies' && group.links.length < 2,
					}"
				>
					<div v-if="group.type === 'links'" class="d-inline-block">
						<router-link v-for="item in group.links" :key="item.id" :to="item.link" @click="dropDownToggle()">
							<div class="position-relative">
								<p>{{ item.name }}</p>
								<span
									v-if="currentUser.notificationCount && item.name === 'Notifications'"
									class="position-absolute translate-middle badge rounded-pill bg-danger notifications-count"
								>
									{{ currentUser.notificationCount }}
									<span class="visually-hidden">unread alerts</span>
								</span>
								<span
									v-if="currentCompany.messageCount + newCompanyMessages && item.name === 'Messages'"
									class="position-absolute start-100 translate-middle badge rounded-pill bg-danger message-count"
								>
									{{ currentCompany.messageCount + newCompanyMessages }}
									<span class="visually-hidden">unread messages</span>
								</span>
							</div>
						</router-link>
					</div>
				</div>
				<div class="linkGroup" v-if="currentUser.companyList.length > 1">
					<div v-for="item in currentUser.companyList" :key="item.id">
						<a @click="changeCompany(item.id)" v-if="!item.selected">
							<div class="position-relative d-inline-block">
								<p>{{ item.name }}</p>
								<span
									v-if="item.messageCount"
									class="position-absolute start-100 translate-middle badge rounded-pill bg-danger message-count-company-list"
								>
									{{ item.messageCount }}
									<span class="visually-hidden">unread messages</span>
								</span>
							</div>
						</a>
					</div>
				</div>

				<div class="linkGroup">
					<a href="mailto:support@map-cms.co.uk?subject=MAP Issue / Feedback">
						<p>Submit Issue / Feedback</p>
					</a>
				</div>
				<div class="linkGroup">
					<a @click="logout()">
						<p>Logout</p>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import packageJson from '../../../package.json';

import { switchCompanyById } from '@/api/auth';

export default {
	name: 'comp-topbar',
	setup() {
		const store = useStore();
		const router = useRouter();
		const currentPath = router.currentRoute.value.path;

		/* ----- DATA ------------------------- */
		const pageTitle = ref(computed(() => store.state.sidebar.pageTitle));
		const newCompanyMessages = computed(() => store.state.currentUser.newCompanyMessages);
		const currentCompany = ref({});
		const dropDownMenu = ref(false);
		const currentUser = ref(computed(() => store.state.currentUser.user));
		const version = packageJson.version;
		const apiVersion = ref('');
		const list = [
			{
				id: 1,
				type: 'links',
				links: [
					{
						id: 1,
						name: 'Notifications',
						link: '/notifications',
						notifications: true,
					},
					{
						id: 2,
						name: 'Messages',
						link: '/messages',
						notifications: false,
					},
				],
			},
		];

		/* ----- METHODS ------------------------- */

		watch(
			currentUser,
			(newValue) => {
				ifMessageCountEqual(newValue.companyList);
			},
			{ deep: true },
		);

		const dropDownToggle = () => {
			dropDownMenu.value = !dropDownMenu.value;
		};
		const changeRouterViewKey = () => {
			const newValue = store.state.currentUser.routerViewKey + 1;
			store.dispatch('currentUser/updateRouterViewKey', newValue);
		};
		const changeCompany = async (item) => {
			try {
				await switchCompanyById(item);
				await store.dispatch('currentUser/updateUsersData');
				updateCompanyName();
				dropDownToggle();
				changeRouterViewKey();
			} catch (err) {
				console.log(err);
			}
		};

		const logout = () => {
			const currentPathWithoutSlash = router.currentRoute.value.fullPath.slice(1);
			localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = '';
			localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = '';
			updateTitle();
			router.push('/login?return-to:' + currentPathWithoutSlash);
		};
		const updateCompanyName = () => {
			apiVersion.value = store.state.currentUser.user.apiVersion.split('Version=')[1].split(',')[0];

			const selectedCompany = currentUser.value.companyList.find((company) => company.selected);
			if (selectedCompany) {
				currentCompany.value = selectedCompany;
				store.commit('currentUser/updateCompany', selectedCompany);
			}
		};
		const updateTitle = () => {
			store.dispatch('sidebar/setPageTitle', '');
		};

		const ifMessageCountEqual = (newUserDataCompanyList) => {
			const selectedCompany = newUserDataCompanyList.find((company) => company.selected);
			const ifCurrentCompanyNeedUpdate =
				selectedCompany.messageCount !== currentCompany.value.messageCount &&
				selectedCompany.id === currentCompany.value.id;

			if (ifCurrentCompanyNeedUpdate) {
				currentCompany.value = selectedCompany;
			}
		};

		/* ----- ONMOUNTED ------------------------- */
		onMounted(() => {
			updateCompanyName();
		});

		return {
			pageTitle,
			currentCompany,
			newCompanyMessages,
			currentUser,
			dropDownToggle,
			list,
			version,
			apiVersion,
			currentPath,
			dropDownMenu,
			changeCompany,
			logout,
		};
	},
};
</script>

<style lang="scss" scoped>
.pageTitle {
	position: fixed;
	top: 20px;
	left: 90px;
	height: 40px;
	display: flex;
	align-items: center;
	align-content: center;
	h1 {
		font-size: 24px;
		font-weight: 700;
		color: var(--cta);
		margin: 0;
	}
}
.comp-topbar {
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 12;

	.userProfile {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 10px;
		padding: 5px;
		background-color: var(--cta);
		cursor: pointer;
		transition: 0.6s ease;

		span {
			font-size: 16px;
			color: var(--text-alt);
		}
		.notification-icons {
			margin-right: 60px;
			top: 55%;
			.notification {
				font-size: 10px;
				top: -5px;
			}
			i {
				font-size: 25px;
			}
			.icon-outline {
				text-shadow: -1px -1px 0 var(--cta), 1px -1px 0 var(--cta), -1px 1px 0 black, 1px 1px 0 black;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.userProfile.alert {
		background-color: red;
	}
	.userProfile.alert:after {
		content: '';
		position: absolute;
		top: -5px;
		right: -5px;
		width: 15px;
		height: 15px;
		background-color: #c3bd43;
		border-radius: 50%;
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.6);
	}
	.dropdown {
		position: absolute;
		top: 100%;
		right: 0;
		width: 0px;
		height: auto;
		max-height: 400px;
		background-color: var(--cta);
		border-radius: 10px 0 10px 10px;
		transition: 0.6s ease all !important;
		overflow: hidden;
		overflow-y: auto;
		z-index: -1;

		.linkGroup {
			padding: 10px;
			border-bottom: solid 1px rgba(255, 255, 255, 0.2);

			a {
				text-decoration: none;
				cursor: pointer;

				p {
					position: relative;
					display: table;
					font-size: 16px;
					font-weight: 400;
					color: var(--text-dark);
					margin: 0;
					padding: 4px 0;
					white-space: nowrap;
				}
				.message-count,
				.notifications-count {
					top: 10px;
				}
				.notifications-count {
					left: 120%;
				}
				.message-count-company-list {
					top: 10px;
					margin-left: 15px;
				}
			}
			a.current {
				cursor: context-menu;

				p {
					font-weight: 400;
				}
			}
		}
		.linkGroup.noBorder {
			border: none;
			padding: 0;
		}
	}
	.dropdown.open {
		right: 0;
		width: 250px;
	}
}
.comp-topbar.active {
	.userProfile {
		border-radius: 10px 10px 0 0;
	}
	.dropdown {
		display: block;
		transition: 5s ease;
		border-radius: 10px 0 10px 10px;
	}
}
</style>
