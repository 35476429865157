<template>
	<div class="Alert-Popup">
		<p>{{ alertMessage }}</p>
	</div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
	name: 'Alert-Popup',
	setup() {
		const store = useStore();

		const alertMessage = ref(computed(() => store.state.alert.currentAlert.message));

		/* ----- ONMOUNTED ------------------------- */
		onMounted(() => {
			// console.log("store.state.alert.currentAlert.message", store.state.alert.currentAlert);
		});

		return {
			store,
			alertMessage,
		};
	},
};
</script>

<style lang="scss" scoped>
.Alert-Popup {
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 300px;
	padding: 6px 10px;
	background-color: var(--cta);
	border-radius: 10px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
	z-index: 11;

	p {
		font-size: 16px;
		font-weight: 500;
		color: var(--background);
		margin: 0;
	}
}
</style>
