const state = () => ({
	show: false,
});

const mutations = {
	showPopup(state) {
		state.show = true;
	},
	hidePopup(state) {
		state.show = false;
	},
};

// actions
const actions = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
