import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import store from '@/_store';
import { postAuth } from '@/api/auth';

async function authprotected(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
	const refreshToken = localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9;
	const token = localStorage.c5ad83ab73e67307a1af0d28ae97fdc4;

	const urlArray = window.location.href.split('/');
	let currentURL = '';
	for (let i = 3; i < urlArray.length; i++) {
		currentURL = currentURL + urlArray[i] + '??slash??';
	}

	if (token) {
		try {
			const { data } = await postAuth({ refreshToken });

			store.commit('currentUser/updateUser', data);
			store.commit('currentUser/resetNewCompanyMessages');
			localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = data.jwtToken;
			localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = data.refreshToken;
			next();
		} catch (err) {
			console.log('Err', err);
			localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = '';
			localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = '';

			store.dispatch('sidebar/setPageTitle', '');
			next('/login');
		}
	} else {
		localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = '';
		localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = '';

		if (currentURL) {
			next('/login?return-to:' + currentURL);
		} else {
			next('/login');
		}
	}
}

async function unauthprotected(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
	const refreshToken = localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9;
	const token = localStorage.c5ad83ab73e67307a1af0d28ae97fdc4;

	const currentURL = window.location.href.split('?return-to:')[1];
	if (token) {
		try {
			const { data } = await postAuth({ refreshToken });

			store.commit('currentUser/updateUser', data);
			localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = data.jwtToken;
			localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = data.refreshToken;

			if (currentURL) {
				next('/' + currentURL.replaceAll('??slash??', ''));
			} else {
				next('/');
			}
		} catch (err) {
			console.log('Err', err);
			localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = '';
			localStorage.d71e0b3cb9973ce8cceb07b7229bb06abbaf82c9 = '';
			store.dispatch('sidebar/setPageTitle', '');
			next();
		}
	} else {
		localStorage.c5ad83ab73e67307a1af0d28ae97fdc4 = '';
		next();
	}
}

export { authprotected, unauthprotected };
