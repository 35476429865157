const state = () => ({
	loaded: false,
	data: null,
});

const mutations = {};

// actions
const actions = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
